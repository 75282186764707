import React from "react";
import "../App.css"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core'
import history from '.././history';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';


function Things() {

  return (
    <div className="App">
      <div className="back"><Button onClick={onClick}><ArrowBackOutlinedIcon color="primary" /></Button></div>

      <header className="App-header">
        <h1>Things I care about</h1>
        <Card className="card-container">
          <CardActionArea>
            <CardMedia
              component="img"
              alt="Vaquita"
              height="200"
              width="450"
              src="https://assets.rebelmouse.io/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbWFnZSI6Imh0dHBzOi8vYXNzZXRzLnJibC5tcy8xOTgxMjg4MC9vcmlnaW4uanBnIiwiZXhwaXJlc19hdCI6MTY4Nzg5NTU5MH0.2XLL9k2mvrFVrHXO8Vrr_5zh6uj5jZojOvOpeDXN6QI/img.jpg?width=1200&quality=85&coordinates=129%2C0%2C129%2C0&height=800"
              title="Vaquita"
            />
            <CardContent className="card-content">
              <Typography gutterBottom variant="h5" component="h2" className="card-text">
                Vaquita
              </Typography>
              <Typography variant="body2" component="p" className="card-text">
                A species of cetacean habiting in the Gulf of California in Mexico. 4 years ago, there were 30
                individuals remaining, now there are only 10 left.
                <Link className="card-text" href="https://www.worldwildlife.org/species/vaquita"> https://www.worldwildlife.org/species/vaquita</Link>
              </Typography>

            </CardContent>
          </CardActionArea>
          {/*<CardActions>*/}
          {/*  <Button size="small" color="primary">*/}
          {/*    Share*/}
          {/*  </Button>*/}
          {/*  <Button size="small" color="primary">*/}
          {/*    Learn More*/}
          {/*  </Button>*/}
          {/*</CardActions>*/}
        </Card>

      </header>
    </div>
  );
}

function onClick() {
  history.push("/");
}

export default Things;
