import React, {Component} from "react";
import {Router, Switch, Route} from "react-router-dom";

import Home from "./Page/Home";
import history from './history';
import Things from "./Page/Things";
import App from "./App";

export default class Routes extends Component {
  render() {
    return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={App}/>
                    <Route exact path='/home' component={Home}/>
                    <Route exact path='/hub' component={Things}/>
                </Switch>
            </Router>
    )
  }
}