import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import React from "react";

function Home() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Hey</h1>
                <ButtonGroup variant="text" color="inherit" aria-label="text primary button group">
                    <Button>Home</Button>
                    <Button>Hub</Button>
                </ButtonGroup>
            </header>
        </div>
    );
}

export default Home;
