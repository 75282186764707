import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import './App.css';

const MyFader = ({text}) => {

  const [fadeProp, setFadeProp] = useState({
    fade: 'fade-in',
  });

  const [counter, setCounter] = useState(0);


  useEffect(() => {
    const timeout = setInterval(() => {
      if (fadeProp.fade === 'fade-in') {
        setFadeProp({
          fade: 'fade-out'
        })

      } else {
        setFadeProp({
          fade: 'fade-in'
        })
         if (counter === text.length - 1) {
          setCounter(0);
        } else {
          setCounter(counter + 1);
        }
      }

    }, 3000);

    return () => clearInterval(timeout)
  })


  return (
          <>
            <h1 className={fadeProp.fade}>{text[counter]}</h1>
          </>
  )
}


MyFader.defaultProps = {
  text: 'Hello World!'
}

MyFader.propTypes = {
  text: PropTypes.string,
}

export default MyFader